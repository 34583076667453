import axios from 'axios';
import {  mapMutations, mapState } from 'vuex';
import moment from 'moment';


const coreMixin = {
    data() {
      return {
            companyEmail: 'support@zamupay.com',
            supportEmail: 'support@zamupay.com',
            salesEmail: 'sales@zamupay.com',
            companyPhoneNo: '+254 740 701 000',
            companyName: 'ZamuPay',
            companyBuilding: '9th Floor, Mitsumi Business Park',
            companyAddress: 'Muthiithi Road, Westlands, Nairobi',
            companyFax: '+254 0123-4567-8900',
            website: 'www.zamupay.com',
            loadingData: false,
            socialMedia: [
                // {media: 'twitter', handle:'https://twitter.com/piconsultingke?lang=en', icon: 'mdi-twitter'},
                {media: 'facebook', handle:'https://www.facebook.com/Zamupay-103071015439775', icon: 'mdi-facebook'},
                {media: 'linkedin', handle:'https://www.linkedin.com/company/zamupay/about/', icon: 'mdi-linkedin'},
                {media: 'instagram', handle:'https://www.instagram.com/zamupay_/', icon: 'mdi-instagram'},


            ],
            // apiUrl: 'http://159.65.175.58/',
            // apiUrl: 'http://127.0.0.1:3333/',
            apiUrl: 'https://zamupay.com/api/',
            nameRules: [
              v => !!v || 'Name is required',
              ],
            categoryRules: [
              v => !!v || 'Category is required',
              ],
            phoneRules: [
                v => !!v || 'Phone No is required',
                ],
            rangeRules: [
                v => !!v || 'Payment Range is required',
                ],
            usernameRules: [
              v => !!v || 'Username is required',
              ],
              country_codeRules: [
                v => !!v || 'Country code is required',
                ],
              
            descriptionRules: [
              v => !!v || 'Description is required',
              ],
              
             emailRules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ],
             subjectRules: [
              v => !!v || 'Subject is required',
             ],
             positionRules: [
              v => !!v || 'Job Title is required',
             ],
             
              messageRules: [
                v => !!v || 'Message is required',
            ],
            passwordRules: {
              required: value => !!value || "Required.",
              min: v => (v && v.length >= 8) || "Min 8 characters"
              }
            
        };
    },
    computed: {
      ...mapState(['s3Path', 'userData']),
        is_screen_small() {
         return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
        },
        is_home() {
            return this.$route.name === 'why-us' || this.$route.name === 'features'  || this.$route.name === 'home' || this.$route.path.includes('admin') || this.$route.path.includes('why-us');
        },
        routeName() {
          return this.$route.name;
        }
    },
    mounted () {
      if(!localStorage.getItem('activeLink')) {
        localStorage.setItem('activeLink', 'home');
        this.setActiveLink('home');
      }

    },

    methods: {
    ...mapMutations(['setAccessToken', 'setSessionData', 'setActiveLink']),

        goHome() {
          localStorage.setItem('activeLink', 'home');
          this.setActiveLink('home');

          this.$router.push('/').catch(()=>{});
        },

        async custom_headers() {
            const authToken = localStorage.token;

            const param = {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: authToken,
              },
            };

            return param;
          },
        async axiosPostRequest(payload) {
            const { endpoint } = payload;
            const { app } = payload;

            const headers = await this.custom_headers();

            const url = `${app}${endpoint}`;

            try {
              const response = await axios.post(`${url}`, payload.params, headers);
              return response;
            } catch (error) {
              return error.response;
            }
          },
          async axiosPutRequest(payload) {
            const { endpoint } = payload;
            const { app } = payload;

            const headers = await this.custom_headers();

            const url = `${app}${endpoint}`;

            try {
              const response = await axios.put(`${url}`, payload.params, headers);
              return response;
            } catch (error) {
              return error.response;
            }
          },

          async axiosGetRequest(payload) {
            const headers = await this.custom_headers();
            const { endpoint } = payload;
            const { params } = payload;
            const { app } = payload;
            const { id } = payload;

            let url = `${app}${endpoint}`;
            url = typeof id === 'undefined' ? url : `${url}/${id}`;

            const values = {
              params,
              headers: headers.headers,
            };
            // eslint-disable-next-line no-restricted-syntax
            for (const value in values) {
              if (values[value] === null || values[value] === undefined) {
                delete values[value];
              }
            }

            try {
              const response = await axios.get(url, values);
              return response;
            } catch (error) {
              return error.response;
            }
          },
          async retrieveUser(id) {
            this.loading = true;
            this.requested = true;

            const endpoint = `api/users/${id}`;

            const fullPayload = {
                app: this.apiUrl,
                endpoint,
            };

            try {
                const response = await this.axiosGetRequest(fullPayload);
                const user = response.status === 200 ? response.data : null;
                this.loading = false;
                return user;
            } catch (error) {
                this.notification = 'Failed to fetch customer data. Kindly try again or contact tech support';
                this.loading = false;
            }
        },
        goBack() {
          this.$router.go(-1);
        },
        async retrieveBlogCategories(page) {
          const endpoint = `blog-categories/categories`;

          const payload = {
            page: page,
          };

          const fullPayload = {
              app: this.apiUrl,
              params: payload,
              endpoint,
          };

          try {
              const response = await this.axiosGetRequest(fullPayload);
              const categories = response.status === 200 ? response.data : null;
              return categories;
          } catch (error) {
            return null;
          }
      },
      async retrieveFAQCategories(page) {
        const endpoint = `faq-categories/categories`;

        const payload = {
          page: page,
        };

        const fullPayload = {
            app: this.apiUrl,
            params: payload,
            endpoint,
        };

        try {
            const response = await this.axiosGetRequest(fullPayload);
            const categories = response.status === 200 ? response.data : null;
            return categories;
        } catch (error) {
          return null;
        }
    },
       retrieveBlogCategory(id) {
        const endpoint = `blog-categories/category/${id}`;

        const fullPayload = {
            app: this.apiUrl,
            endpoint,
        };

        try {
            const response = this.axiosGetRequest(fullPayload);
            const category = response.status === 200 ? response.data : null;
            return category;
        } catch (error) {
          return null;
        }
    },
    async retrieveBlogPost(id) {
      const endpoint = `blog/post/${id}`;

      const fullPayload = {
          app: this.apiUrl,
          endpoint,
      };

      try {
          const response = await this.axiosGetRequest(fullPayload);
          const post = response.status === 200 ? response.data : null;
          return post;
      } catch (error) {
        return null;
      }
  },
  async retrieveFAQPost(id) {
    const endpoint = `FAQs/post/${id}`;

    const fullPayload = {
        app: this.apiUrl,
        endpoint,
    };

    try {
        const response = await this.axiosGetRequest(fullPayload);
        const post = response.status === 200 ? response.data : null;
        return post;
    } catch (error) {
      return null;
    }
},
    async retrievePosts(page) {
      this.loadingData = true;

      const payload = {
        page: page,
        appID: 1,
      };
  
      const endpoint = `blog/postPerApp`;
  
      const fullPayload = {
          app: this.apiUrl,
          params: payload,
          endpoint,
      };
  
      try {
          const response = await this.axiosPostRequest(fullPayload);
          const posts = response.status === 200 ? response.data : null;
          this.loadingData = false;

          return posts;
      } catch (error) {
        this.loadingData = false;
        return null;
        
      }
     },
     async retrieveActivePosts(page) {
      this.loadingData = true;

      const payload = {
        page: page,
        appID: 1,
      };
  
      const endpoint = `blog/active`;
  
      const fullPayload = {
          app: this.apiUrl,
          params: payload,
          endpoint,
      };
  
      try {
          const response = await this.axiosPostRequest(fullPayload);
          const posts = response.status === 200 ? response.data : null;
          this.loadingData = false;

          return posts;
      } catch (error) {
        this.loadingData = false;
        return null;
        
      }
     },
     async retrieveActiveCategories(page) {
      this.loadingData = true;

      const payload = {
        page: page,
      };
  
      const endpoint = `blog-categories/active`;
  
      const fullPayload = {
          app: this.apiUrl,
          params: payload,
          endpoint,
      };
  
      try {
          const response = await this.axiosPostRequest(fullPayload);
          const posts = response.status === 200 ? response.data : null;
          this.loadingData = false;

          return posts;
      } catch (error) {
        this.loadingData = false;
        return null;
        
      }
     },
     async retrieveActiveFAQs(page) {
      this.loadingData = true;

      const payload = {
        page: page,
      };
  
      const endpoint = `FAQs/active`;
  
      const fullPayload = {
          app: this.apiUrl,
          params: payload,
          endpoint,
      };
  
      try {
          const response = await this.axiosGetRequest(fullPayload);
          const posts = response.status === 200 ? response.data : null;
          this.loadingData = false;

          return posts;
      } catch (error) {
        this.loadingData = false;
        return null;
        
      }
     },
     async retrieveActiveFAQCategories(page) {
      this.loadingData = true;

      const payload = {
        page: page,
      };
  
      const endpoint = `faq-categories/active`;
  
      const fullPayload = {
          app: this.apiUrl,
          params: payload,
          endpoint,
      };
  
      try {
          const response = await this.axiosGetRequest(fullPayload);
          const posts = response.status === 200 ? response.data : null;
          this.loadingData = false;

          return posts;
      } catch (error) {
        this.loadingData = false;
        return null;
        
      }
     },
     async retrieveCategory(id) {
      const endpoint = `faq-categories/category/${id}`;

      const fullPayload = {
          app: this.apiUrl,
          endpoint,
      };

      try {
          const response = await this.axiosGetRequest(fullPayload);
          const category = response.status === 200 ? response.data : null;
          return category;
      } catch (error) {
        return null;
      }
  },
  async retrieveActiveCountries(page) {
    this.loadingData = true;

    const payload = {
      page,
    };

    const endpoint = `countries/active`;

    const fullPayload = {
        app: this.apiUrl,
        params: payload,
        endpoint,
    };

    try {
        const response = await this.axiosGetRequest(fullPayload);
        const countries = response.status === 200 ? response.data : null;
        this.loadingData = false;
        return countries;
    } catch (error) {
        this.loading = false;
        return null;
    }
},
  async retrieveActivePaymentVolumes(page) {
    this.loadingData = true;

    const payload = {
      page,
    };

    const endpoint = `payment-volumes/active`;

    const fullPayload = {
        app: this.apiUrl,
        params: payload,
        endpoint,
    };

    try {
        const response = await this.axiosGetRequest(fullPayload);
        const volumes = response.status === 200 ? response.data : null;
        this.loadingData = false;
        return volumes;
    } catch (error) {
        this.loading = false;
        return null;
    }
  },
    async retrieveCountry(id) {
      const endpoint = `country/${id}`;

      const fullPayload = {
          app: this.apiUrl,
          endpoint,
      };

      try {
          const response = await this.axiosGetRequest(fullPayload);
          const country = response.status === 200 ? response.data : null;
          return country;
      } catch (error) {
        return null;
      }
   },
   async retrievePaymentVolume(id) {
    const endpoint = `payment-volume/${id}`;

    const fullPayload = {
        app: this.apiUrl,
        endpoint,
    };

    try {
        const response = await this.axiosGetRequest(fullPayload);
        const volume = response.status === 200 ? response.data : null;
        return volume;
    } catch (error) {
      return null;
    }
},
    formatDate(date, format) {
      const formated = moment(date).format(format);
      return formated;
     },
    }
};
export default coreMixin;
    